import { $ } from "./api";
import { SipShopCoreServicesVoImportHistory, SipShopCoreServicesVoCompanyUserTable, SipShopCoreServicesVoCompanyUserEnabled, SipShopCoreServicesVoCompanyAddressTable, SipShopCoreServicesVoCompanyAddressEnabled } from "./admin"

export const setupAdminService = window =>
    (function(window) {
        var mixinStub = function(operation, args) {
            operation.data = {
                endPoint: window.sipShopCoreServicesAdminProxy.endPoint,
                server: window.sipShopCoreServicesAdminProxy.server,
                arguments: args,
                complete: false,
                pending: false,
                result: null,
                exception: null,
                error: [],
                messages: [],
                ajax: null
            };
            operation.server = function(server) {
                this.data.server = server;
                return this;
            };
            operation.endPoint = function(endPoint) {
                this.data.endPoint = endPoint;
                return this;
            };
        };

        var transport = function(operation, url, postData) {
            var errorHandler = function(error) {
                operation.data.success = false;
                operation.data.error = error;
                if (operation.data._errorCallback) {
                    operation.data._errorCallback(operation);
                }
            };
            operation.data.ajax = $.ajax({
                dataType: "json",
                url: url,
                type: postData === null ? "GET" : "POST",
                data: JSON.stringify(postData),

                success: function(data) {
                    if (data !== null) {
                        operation.data.result = data.value;
                        operation.data.exception = data.exception;
                        operation.data.messages = data.messages;
                        if (
                            operation.data.exception !== null &&
                            typeof operation.data._errorCallback == "function"
                        ) {
                            errorHandler(data);
                        } else if (!operation.data.exception) {
                            operation.data.success = true;
                            operation.data._successCallback(operation);
                        }
                    } else {
                        errorHandler("transport threw up");
                    }
                    operation.data.pending = false;
                },
                error: errorHandler
            });
            return operation;
        };
        // if you do not like "sipShopCoreServicesCartProxy" as a name change,
        // then call ->clientNamespace('WhatEver.You.Like') on the server
        return {
            server: "", // 'http://sip-dev'  it is better not to add your server here ;)
            endPoint:
                "/foomo/modules/SipShop.Core/services/admin.php/Foomo.Services.RPC/serve",
            operations: {
                /**
                 * Fetches a certain page of the import history
                 *
                 * @param useES boolean if true use the Spanish database
                 * @param pageSize number number of entries per page
                 * @param page number fetch the pageth page of the history (0 is the first page)
                 *
                 * @return SipShopCoreServicesVoImportHistory
                 */
                getImportHistory: function(useES, pageSize, page) {
                    return new (function(useES, pageSize, page) {
                        mixinStub(this, {useES: useES, pageSize: pageSize, page: page});
                        this.execute = function(successCallback) {
                            this.data._successCallback = successCallback;
                            var url =
                                this.data.server + this.data.endPoint + "/getImportHistory";
                            var postData = {useES: useES, pageSize: pageSize, page: page};
                            return transport(this, url, postData);
                        };
                        this.error = function(errorCallback) {
                            this.data._errorCallback = errorCallback;
                            return this;
                        };
                        return this;
                    })(useES, pageSize, page);
                },
                /**
                 * Starts an import of master data to the dev database
                 *
                 * @param useES boolean if true use the Spanish database
                 *
                 * @return boolean
                 */
                startImport: function(useES) {
                    return new (function(useES) {
                        mixinStub(this, {useES: useES});
                        this.execute = function(successCallback) {
                            this.data._successCallback = successCallback;
                            var url =
                                this.data.server + this.data.endPoint + "/startImport";
                            return transport(this, url, {useES: useES});
                        };
                        this.error = function(errorCallback) {
                            this.data._errorCallback = errorCallback;
                            return this;
                        };
                        return this;
                    })(useES);
                },
                /**
                 * Imports a dump to the prod database
                 *
                 * @param useES boolean if true use the Spanish database
                 * @param filenameWoExtension string filename of the dump (without filename extension)
                 *
                 * @return boolean
                 */
                importDump: function(useES, filenameWoExtension) {
                    return new (function(useES, filename) {
                        mixinStub(this, {useES: useES, fileName: filename});
                        this.execute = function(successCallback) {
                            this.data._successCallback = successCallback;
                            var url =
                                this.data.server + this.data.endPoint + "/importDump";
                            return transport(this, url, {useES: useES, fileName: filename});
                        };
                        this.error = function(errorCallback) {
                            this.data._errorCallback = errorCallback;
                            return this;
                        };
                        return this;
                    })(useES, filenameWoExtension);
                },
                /**
                 * Returns the users of the eShop
                 *
                 * @param useES boolean if true use the Spanish database
                 * @param pageSize number number of entries per page
                 * @param page number fetch the pageth page of the user entries (0 is the first page)
                 * @param searchTerm string if the value is not 'all', it is matched with companySapId and as substring of companyName, firstname, lastname and email of the user
                 * @param filter string possible values are 'all', 'enabled', 'disabled'
                 * @param sortField string possible values are 'companyName', 'lastName', 'firstName', 'email', 'enabled'
                 * @param sortAsc boolean if true sort ascending, otherwise, sort descending
                 *
                 * @return SipShopCoreServicesVoCompanyUserTable
                 */
                getUsers: function(useES, pageSize, page, searchTerm, filter, sortField, sortAsc) {
                    return new (function(useES, pageSize, page, searchTerm, filter, sortField, sortAsc) {
                        mixinStub(this, {useES: useES, pageSize: pageSize, page: page, searchTerm: searchTerm, filter: filter, sortField: sortField, sortAsc: sortAsc});
                        this.execute = function(successCallback) {
                            this.data._successCallback = successCallback;
                            var url =
                                this.data.server + this.data.endPoint + "/getUsers";
                            var postData = {useES: useES, pageSize: pageSize, page: page, searchTerm: searchTerm, filter: filter, sortField: sortField, sortAsc: sortAsc};
                            return transport(this, url, postData);
                        };
                        this.error = function(errorCallback) {
                            this.data._errorCallback = errorCallback;
                            return this;
                        };
                        return this;
                    })(useES, pageSize, page, searchTerm, filter, sortField, sortAsc);
                },
                /**
                 * Change the enabled flag of the user accounts passed in as parameter
                 *
                 * @param useES boolean if true use the Spanish database
                 * @param users SipShopCoreServicesVoCompanyUserEnabled[] users whose enabled status shall be changed
                 *
                 * @return success boolean
                 */
                toggleUserEnablement: function(useES, users) {
                    return new (function(useES, users) {
                        mixinStub(this, {useES: useES, users: users});
                        this.execute = function(successCallback) {
                            this.data._successCallback = successCallback;
                            var url =
                                this.data.server + this.data.endPoint + "/toggleUserEnablement";
                            var postData = {useES: useES, users: users};
                            return transport(this, url, postData);
                        };
                        this.error = function(errorCallback) {
                            this.data._errorCallback = errorCallback;
                            return this;
                        };
                        return this;
                    })(useES, users);
                },
                /**
                 * Creates or updates a user of the eShop
                 *
                 * @param useES boolean if true use the Spanish database
                 * @param user SipShopCoreServicesVoUser user data if a new user is to be inserted user->id equals zero
                 * @param password string if not empty the password is set for the user (required for a new user)
                 *
                 * @return SipShopCoreServicesVoUser|null
                 */
                upsertUser: function(useES, user, password) {
                    return new (function(useES, user, password) {
                        mixinStub(this, {useES: useES, user:user, password:password});
                        this.execute = function(successCallback) {
                            this.data._successCallback = successCallback;
                            var url =
                                this.data.server + this.data.endPoint + "/upsertUser";
                            var postData = {useES: useES, user:user, password:password};
                            return transport(this, url, postData);
                        };
                        this.error = function(errorCallback) {
                            this.data._errorCallback = errorCallback;
                            return this;
                        };
                        return this;
                    })(useES, user, password);
                },
                /**
                 * Find a certain company user of the eShop
                 *
                 * @param useES boolean if true use the Spanish database
                 * @param searchMode string must be one of (companyId|userIdOrName)
                 * @param searchTerm string if searchMode is companyId the value is the id of company else (part of) the name or id of the user
                 *
                 * @return SipShopCoreServicesVoUser[]
                 */
                searchCompanyUser: function(useES, searchMode, searchTerm) {
                    return new (function(useES, searchMode, searchTerm) {
                        mixinStub(this, {useES: useES, searchMode: searchMode, searchTerm: searchTerm});
                        this.execute = function(successCallback) {
                            this.data._successCallback = successCallback;
                            var url =
                                this.data.server + this.data.endPoint + "/fetchCompanyUsers";
                            var postData = {useES: useES, searchMode: searchMode, searchTerm: searchTerm};
                            return transport(this, url, postData);
                        };
                        this.error = function(errorCallback) {
                            this.data._errorCallback = errorCallback;
                            return this;
                        };
                        return this;
                    })(useES, searchMode, searchTerm);
                },
                /**
                 * Returns the addresses (Warenempfänger) of the eShop
                 *
                 * @param useES boolean if true use the Spanish database
                 * @param pageSize number number of entries per page
                 * @param page number fetch the pageth page of the addresses (0 is the first page)
                 * @param searchTerm string if the value is not 'all', it is matched with companySapId and as substring match of the company name or the street or 'zip city' of the address
                 * @param filter string possible values are 'all', 'enabled', 'disabled'
                 * @param sortField string possible values are 'companyName', 'sapId', 'addressText', 'enabled'
                 * @param sortAsc boolean if true sort ascending, otherwise, sort descending
                 *
                 * @return SipShopCoreServicesVoCompanyAddressTable
                 */
                getAddresses: function(useES, pageSize, page, searchTerm, filter, sortField, sortAsc) {
                    return new (function(useES, pageSize, page, searchTerm, filter, sortField, sortAsc) {
                        mixinStub(this, {useES: useES, pageSize: pageSize, page: page, searchTerm: searchTerm, filter: filter, sortField: sortField, sortAsc: sortAsc});
                        this.execute = function(successCallback) {
                            this.data._successCallback = successCallback;
                            var url =
                                this.data.server + this.data.endPoint + "/getAddresses";
                            var postData = {useES: useES, pageSize: pageSize, page: page, searchTerm: searchTerm, filter: filter, sortField: sortField, sortAsc: sortAsc};
                            return transport(this, url, postData);
                        };
                        this.error = function(errorCallback) {
                            this.data._errorCallback = errorCallback;
                            return this;
                        };
                        return this;
                    })(useES, pageSize, page, searchTerm, filter, sortField, sortAsc);
                },
                /**
                 * Change the enabled flag of the addresses passed in as parameter
                 *
                 * @param useES boolean if true use the Spanish database
                 * @param SipShopCoreServicesVoCompanyAddressEnabled[] addresses whose enabled status shall be changed
                 *
                 * @return success boolean
                 */
                toggleAddressEnablement: function(useES, addresses) {
                    return new (function(useES, addresses) {
                        mixinStub(this, {useES: useES, addresses: addresses});
                        this.execute = function(successCallback) {
                            this.data._successCallback = successCallback;
                            var url =
                                this.data.server + this.data.endPoint + "/toggleAddressEnablement";
                            var postData = {useES: useES, addresses: addresses};
                            return transport(this, url, postData);
                        };
                        this.error = function(errorCallback) {
                            this.data._errorCallback = errorCallback;
                            return this;
                        };
                        return this;
                    })(useES, addresses);
                },
                /**
                 * Creates or updates a company address (Warenempfänger) of the eShop
                 *
                 * @param useES boolean if true use the Spanish database
                 * @param address SipShopCoreServicesVoCompanyAddress if a new address is to be inserted address->id equals zero
                 *
                 * @return SipShopCoreServicesVoCompanyAddress|null
                 */
                upsertAddress: function(useES, address) {
                    return new (function(useES, address) {
                        mixinStub(this, {useES: useES, address:address});
                        this.execute = function(successCallback) {
                            this.data._successCallback = successCallback;
                            var url =
                                this.data.server + this.data.endPoint + "/upsertAddress";
                            var postData = {useES: useES, address:address};
                            return transport(this, url, postData);
                        };
                        this.error = function(errorCallback) {
                            this.data._errorCallback = errorCallback;
                            return this;
                        };
                        return this;
                    })(useES, address);
                },
                /**
                 * Find a certain company address (Warenempfänger) of the eShop
                 *
                 * @param useES boolean if true use the Spanish database
                 * @param searchMode string must be one of (companyId|addressIdOrName)
                 * @param searchTerm string if searchMode is companyId the value is the id of company else (part of) the name or id of the address
                 *
                 * @return SipShopCoreServicesVoCompanyAddress[]
                 */
                searchCompanyAddress: function(useES, searchMode, searchTerm) {
                    return new (function(useES, searchMode, searchTerm) {
                        mixinStub(this, {useES: useES, searchMode: searchMode, searchTerm: searchTerm});
                        this.execute = function(successCallback) {
                            this.data._successCallback = successCallback;
                            var url =
                                this.data.server + this.data.endPoint + "/fetchCompanyAddresses";
                            var postData = {useES: useES, searchMode: searchMode, searchTerm: searchTerm};
                            return transport(this, url, postData);
                        };
                        this.error = function(errorCallback) {
                            this.data._errorCallback = errorCallback;
                            return this;
                        };
                        return this;
                    })(useES, searchMode, searchTerm);
                },
                /**
                 * Returns the companies (Kunden) of the eShop
                 *
                 * @param useES boolean if true use the Spanish database
                 * @param pageSize number number of entries per page
                 * @param page number fetch the pageth page of the addresses (0 is the first page)
                 * @param searchTerm string if not 'all' the search term will be used as full match in sapId and as substring match of the name of the company or the name of the sip contact person
                 * @param sortField string possible values are 'name', 'sapId', 'deliveryDay', 'brand', 'country', 'consi', 'sipContact'
                 * @param sortAsc boolean if true sort ascending, otherwise, sort descending
                 *
                 * @return SipShopCoreServicesVoCompanyTable
                 */
                getCompanies: function(useES, pageSize, page, searchTerm, sortField, sortAsc) {
                    return new (function(useES, pageSize, page, searchTerm, sortField, sortAsc) {
                        mixinStub(this, {useES: useES, pageSize: pageSize, page: page, searchTerm: searchTerm, sortField: sortField, sortAsc: sortAsc});
                        this.execute = function(successCallback) {
                            this.data._successCallback = successCallback;
                            var url =
                                this.data.server + this.data.endPoint + "/getCompanies";
                            var postData = {useES: useES, pageSize: pageSize, page: page, searchTerm: searchTerm, sortField: sortField, sortAsc: sortAsc};
                            return transport(this, url, postData);
                        };
                        this.error = function(errorCallback) {
                            this.data._errorCallback = errorCallback;
                            return this;
                        };
                        return this;
                    })(useES, pageSize, page, searchTerm, sortField, sortAsc);
                },
                /**
                 * Creates or updates a company of the eShop
                 *
                 * @param useES boolean if true use the Spanish database
                 * @param company SipShopCoreServicesVoCompany if a new company is to be inserted company->id equals zero
                 *
                 * @return SipShopCoreServicesVoCompany|null
                 */
                upsertCompany: function(useES, company) {
                    return new (function(useES, company) {
                        mixinStub(this, {useES: useES, company:company});
                        this.execute = function(successCallback) {
                            this.data._successCallback = successCallback;
                            var url =
                                this.data.server + this.data.endPoint + "/upsertCompany";
                            var postData = {useES: useES, company:company};
                            return transport(this, url, postData);
                        };
                        this.error = function(errorCallback) {
                            this.data._errorCallback = errorCallback;
                            return this;
                        };
                        return this;
                    })(useES, company);
                },
                /**
                 * Find a certain company (Kunde) of the eShop
                 *
                 * @param useES boolean if true use the Spanish database
                 * @param searchMode string must be one of (companyId|companyName)
                 * @param searchTerm string if searchMode is companyId the value is the id of the company else (part of) the name
                 *
                 * @return SipShopCoreServicesVoCompany[]
                 */
                searchCompany: function(useES, searchMode, searchTerm) {
                    return new (function(useES, searchMode, searchTerm) {
                        mixinStub(this, {useES: useES, searchMode: searchMode, searchTerm: searchTerm});
                        this.execute = function(successCallback) {
                            this.data._successCallback = successCallback;
                            var url =
                                this.data.server + this.data.endPoint + "/fetchCompanies";
                            var postData = {useES: useES, searchMode: searchMode, searchTerm: searchTerm};
                            return transport(this, url, postData);
                        };
                        this.error = function(errorCallback) {
                            this.data._errorCallback = errorCallback;
                            return this;
                        };
                        return this;
                    })(useES, searchMode, searchTerm);
                },
            }
        };
    })(window);
